import "./App.css";

function App() {
  return (
    <div id="info" className="container-xl text-center d-flex my-5 align-items-center shadow p-3 mb-5 bg-body-emphasis rounded" style={{width: "100%" ,height: "30rem"}}>
     <p className="display-3 text-center align-middle" style={{width: "100%"}}> Hi <br/> I AM RAVENDRA PATEL </p><br/>
     
     <span><img className="image" src="/clipart1442641.png" style={{width:"250px"}} /></span>
     
    </div>
  );
}

export default App;
